import request from "@/utils/request";

// 获取table
export function getTable(params) {
  return request({
    url: "/user/user-list",
    method: "Get",
    params: params,
  });
}

// 切换账号状态
export function switchUserStatus(params) {
  return request({
    url: "/user/user-status",
    method: "Patch",
    params: params,
  });
}

// 注册(添加用户)
export function register(requestBody) {
  return request({
    url: "/user/register",
    method: "Post",
    data: requestBody,
  });
}

// 修改
export function editUser(params, requestBody) {
  return request({
    url: "/user/user-info",
    method: "Patch",
    params: params,
    data: requestBody,
  });
}

// 删除
export function deleteUser(params) {
  return request({
    url: "/user",
    method: "Delete",
    params: params,
  });
}

// 获取用户绑定的角色
export function getRoleByUser(params) {
  return request({
    url: "/user/user-role",
    method: "Get",
    params: params,
  });
}

// 为用户绑定角色
export function setRoleByUser(params) {
  return request({
    url: "/user/user-role",
    method: "Post",
    params: params,
  });
}

// 为用户绑定监控点
export function bindMonitoryPoint(requestBody) {
  return request({
    url: "/user/user-monitory-point",
    method: "Post",
    data: requestBody,
  });
}

// 获取权限动态显示菜单菜单
export function getAuthority(params) {
  return request({
    url: "/user/user-authority",
    method: "Get",
    params: params,
  });
}
