import axios from "axios";
import { ElMessage } from "element-plus";
import _ from "lodash";
import router from "@/router";

// 创建axios实例
const service = axios.create({
  // baseURL: 'http://127.0.0.1:6379', // api的base_url
  baseURL: "https://www.hltcyun.com:6379", // api的base_url
  // baseURL: "http://www.hltcyun.com:6379", // api的base_url
  // headers: {
  //   ContentType: 'application/json;charset=utf8'
  // },
  timeout: 30000, // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 请求发起前携带token
    config.headers.common["token"] = document.cookie;
    // console.log("这是请求拦截器:\n", JSON.stringify(config))
    // 过滤请求参数中的空格
    for (let paramsKey in config.params) {
      if (typeof config.params[paramsKey] === "string") {
        config.params[paramsKey] = config.params[paramsKey].trim();
      }
    }
    // 数据库中的页面是从0开始,所以currentPage - 1
    if (_.findKey(config, "currentPage") !== undefined) {
      if (config.params.currentPage >= 1) config.params.currentPage--;
    }
    return config;
  },
  (error) => {
    console.log(error);
    ElMessage.error(error.message);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // console.log("这是响应拦截器:\n", JSON.stringify(response))
    // 二进制文件直接放行
    if (response.config.responseType === "arraybuffer") {
      return response;
    }
    if (response.data.code === 200) {
      // 数据库中的页面是从0开始,所以currentPage + 1
      if (_.findKey(response.data, "currentPage") !== undefined) {
        response.data.data.currentPage++;
      }
      if (
        response.config.method !== "get" &&
        response.config.method !== "GET" &&
        response.config.method !== "Get"
      ) {
        // 弹框提示
        ElMessage({
          showClose: true,
          message: response.data.message,
          type: "success",
        });
      }
      return response.data;
    } else if (response.data.code === 401) {
      // 暂未登录或token已经过期
      ElMessage({
        showClose: true,
        message: response.data.message,
        type: "warning",
      });
      document.cookie = undefined;
      router.push("/login");
    } else if (response.data.code === 403) {
      // 没有相关权限, 跳转403
      ElMessage({
        showClose: true,
        message: "该账号无权访问该页面",
        type: "warning",
      });
      router.push("/403");
    } else {
      // code = 500
      ElMessage.error(response.data.message);
      // return Promise.reject();
    }
  },
  (error) => {
    console.log(error);
    ElMessage.error(error.message);
  }
);

export default service;