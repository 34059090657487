<template>
  <el-config-provider :locale="locale">
    <slot name="app">
      <router-view />
    </slot>
  </el-config-provider>
</template>

<script>
import { defineComponent } from 'vue'
import { ElConfigProvider } from 'element-plus'

import zhCn from 'element-plus/lib/locale/lang/zh-cn'

export default defineComponent({
  components: {
    ElConfigProvider,
  },
  setup() {
    return {
      locale: zhCn,
    }
  },
})
</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css";

.el-card {
  margin-bottom: 1%;
}

.el-select {
  width: 100px;
}

/* 解决element-ui的table表格控件表头与内容列不对齐问题 */
.el-table th.gutter {
  display: table-cell !important;
}

.el-table th {
  display: table-cell !important;
}
</style>
