import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import installElementPlus from "./plugins/element";
import "./assets/css/icon.css";
import "./utils/log";
import "./utils/Date";
import _ from "lodash";
// 引入中文包
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
// 处理缓存问题
require("../package.json").version
const VUE_APP_VERSION = require("../package.json").version
const vers = window.localStorage.getItem("appVersion");
if (VUE_APP_VERSION != vers) {
  localStorage.clear();
  window.localStorage.setItem("appVersion", VUE_APP_VERSION);
  location.reload();
}

const app = createApp(App);
app.config.globalProperties.$_ = _;
installElementPlus(app);
app.use(store).use(router);
app.use(ElementPlus, {
  locale: zhCn,
});
app.mount("#app");
