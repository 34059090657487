import {createRouter, createWebHistory} from "vue-router";
// Layout
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard"
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      // ==========================
      // = 主页
      // ==========================
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: "系统首页",
          keepAlive: false
        },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */
            "../views/Dashboard.vue"
          )
      },
      // ==========================
      // = 数据查看
      // ==========================
      {
        path: "/real-monitor",
        name: "real-monitor",
        meta: {
          title: "实时监控",
          keepAlive: false
        },
        component: () => import("../views/data/real/index")
      },
      {
        path: "/monitory-point-map",
        name: "monitory-point-map",
        meta: {
          title: "地图",
          keepAlive: false
        },
        component: () => import("../views/data/real/map/MonitoryPointMap")
      },
      {
        path: "/data-check",
        name: "data-check",
        meta: {
          title: "数据查看",
          keepAlive: true
        },
        component: () => import("../views/data/check/index")
      },
      {
        path: "/data-detail",
        name: "data-detail",
        meta: {
          title: "详细数据",
          keepAlive: false
        },
        component: () => import("../views/data/check/dataDetail/index")
      },
      {
        path: "/data-alert",
        name: "data-alert",
        meta: {
          title: "报警数据",
          keepAlive: false
        },
        component: () => import("../views/data/alert/index")
      },
      // ==========================
      // = 数据统计
      // ==========================
      {
        path: "/dataIntegrityRate",
        name: "dataIntegrityRate",
        meta: {
          title: "数据完整率",
          keepAlive: false
        },
        component: () => import("../views/statistic/index")
      },
      {
        path: "/statistic-month",
        name: "statisticMonth",
        meta: {
          title: "按月统计",
          keepAlive: false
        },
        component: () => import("../views/statistic/month/index")
      },
      // ==========================
      // = 信息管理
      // ==========================
      {
        path: "/area",
        name: "area",
        meta: {
          title: "区域管理",
          keepAlive: false
        },
        component: () => import("../views/info/area/index")
      },
      {
        path: "/wechatBot",
        name: "wechatBot",
        meta: {
          title: "群机器人管理",
          keepAlive: false
        },
        component: () => import("../views/info/wechatbot/index")
      },
      {
        path: "/wechatBotQRCode",
        name: "wechatBotQRCode",
        meta: {
          title: "微信登陆",
          keepAlive: false
        },
        component: () => import("../views/info/wechatlogin/index")
      },
      {
        path: "/companyInfo",
        name: "companyInfo",
        meta: {
          title: "企业信息",
          keepAlive: false
        },
        component: () => import("../views/info/company/companyInfo/index")
      },
      {
        path: "/companyType",
        name: "companyType",
        meta: {
          title: "企业类型",
          keepAlive: false
        },
        component: () => import("../views/info/company/companyType/index")
      },
      {
        path: "/companyLevel",
        name: "companyLevel",
        meta: {
          title: "企业级别",
          keepAlive: false
        },
        component: () => import("../views/info/company/companyLevel/index")
      },
      {
        path: "/pointInfo",
        name: "pointInfo",
        meta: {
          title: "监控点信息",
          keepAlive: false
        },
        component: () => import("../views/info/point/pointInfo/index")
      },
      {
        path: "/pointBinding",
        name: "pointBinding",
        meta: {
          title: "绑定污染物",
          keepAlive: false
        },
        component: () =>
          import("../views/info/point/pointInfo/MonitoryPointBinding")
      },
      {
        path: "/pointType",
        name: "pointType",
        meta: {
          title: "监控点类型",
          keepAlive: false
        },
        component: () => import("../views/info/point/pointType/index")
      },
      {
        path: "/pollutionInfo",
        name: "pollutionInfo",
        meta: {
          title: "污染物信息",
          keepAlive: false
        },
        component: () => import("../views/info/pollution/pollutionInfo/index")
      },
      {
        path: "/pollutionLink",
        name: "pollutionLink",
        meta: {
          title: "污染物兼容",
          keepAlive: false
        },
        component: () => import("../views/info/pollution/pollutionLink/index")
      },
      {
        path: "/message",
        name: "message",
        meta: {
          title: "短信配置",
          keepAlive: false
        },
        component: () => import("../views/info/message/index")
      },
      // ==========================
      // = 站房管理
      // ==========================
      {
        path: "/equipment",
        name: "equipment",
        meta: {
          title: "设备管理",
          keepAlive: false
        },
        component: () => import("../views/room/equipment/index")
      },
      {
        path: "/equipment-countercharge",
        name: "equipment-countercharge",
        meta: {
          title: "设备管理",
          keepAlive: false
        },
        component: () => import("../views/room/countercharge/index")
      },
      // ==========================
      // = 日志管理
      // ==========================
      {
        path: "/datagramLog",
        name: "datagramLog",
        meta: {
          title: "报文日志",
          keepAlive: false
        },
        component: () => import("../views/log/datagram/index")
      },
      {
        path: "/counterchargeLog",
        name: "counterchargeLog",
        meta: {
          title: "反控日志",
          keepAlive: false
        },
        component: () => import("../views/log/countercharge/index")
      },
      {
        path: "/operationLog",
        name: "operationLog",
        meta: {
          title: "操作日志",
          keepAlive: false
        },
        component: () => import("../views/log/operation/index")
      },
      {
        path: "/offLineLog",
        name: "offLineLog",
        meta: {
          title: "断线日志",
          keepAlive: false
        },
        component: () => import("../views/log/offLine/index")
      },
      {
        path: "/messageLog",
        name: "messageLog",
        meta: {
          title: "短信日志",
          keepAlive: false
        },
        component: () => import("../views/log/message/index")
      },
      {
        path: "/offLineStatisticLog",
        name: "offLineStatisticLog",
        meta: {
          title: "断线统计",
          keepAlive: false
        },
        component: () => import("../views/log/offLineStatistic/index")
      },
      // ==========================
      // = 系统管理
      // ==========================
      {
        path: "/user",
        name: "user",
        meta: {
          title: "用户管理",
          keepAlive: false
        },
        component: () => import("../views/sys/user/index")
      },
      {
        path: "/bind-monitory-point",
        name: "bind-monitory-point",
        meta: {
          title: "绑定监控点",
          keepAlive: false
        },
        component: () => import("../views/sys/user/dialog/BindMonitoryPoint")
      },
      {
        path: "/role",
        name: "role",
        meta: {
          title: "角色管理",
          keepAlive: false
        },
        component: () => import("../views/sys/role/index")
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录",
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "login" */
        "../views/Login"
      )
  },
  {
    path: "/403",
    name: "403",
    meta: {
      title: "无权访问",
      keepAlive: false
    },
    component: () =>
      import(
        /* webpackChunkName: "login" */
        "../views/403"
      )
  },
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: () => import("../views/404")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  // 标签名称
  // document.title = `环境监控系统`;
  const token = document.cookie;
  if ((token === "undefined" || token === "") && to.path !== "/login") {
    next("/login");
  } else if (token !== "undefined" && token !== "" && to.path === "/login") {
    next("/");
  } else {
    next();
  }
});

export default router;
