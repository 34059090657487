import request from "@/utils/request";

// 获取统计图数据
export function getStatisticalGraphData() {
  return request({
    url: "/home/statistical-graph",
    method: "Get",
  });
}

// 获取用户信息
export function getUserInfo() {
  return request({
    url: "/home/user-info",
    method: "Get",
  });
}

// 获取MonitoryPoint option
export function getMonitoryPointOptions(params) {
  return request({
    url: "/home/monitory-point-name",
    method: "Get",
    params: params,
  });
}
