import request from "@/utils/request";

// 登录
export function login(params) {
  return request({
    url: "/login",
    method: "Post",
    params: params,
  });
}

// 登出
export function logout(params) {
  return request({
    url: "/logout",
    method: "Post",
    params: params,
  });
}
