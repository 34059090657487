<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="collapse"
      background-color="#324157"
      text-color="#bfcbd9"
      active-text-color="#20a0ff"
      unique-opened
      router
    >
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-sub-menu
            v-if="authorityNames.indexOf(item.authority) !== -1"
            :index="item.index"
            :key="item.index"
          >
            <template #title>
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-sub-menu
                v-if="
                  subItem.subs &&
                  authorityNames.indexOf(subItem.authority) !== -1
                "
                :index="subItem.index"
                :key="subItem.index"
              >
                <template #title>{{ subItem.title }}</template>
                <el-menu-item
                  v-for="(threeItem, i) in subItem.subs"
                  v-show="authorityNames.indexOf(threeItem.authority) !== -1"
                  :key="i"
                  :index="threeItem.index"
                >
                  <!--三级菜单-->
                  {{ threeItem.title }}
                </el-menu-item>
              </el-sub-menu>
              <el-menu-item
                v-else
                v-show="authorityNames.indexOf(subItem.authority) !== -1"
                :index="subItem.index"
                :key="subItem.index"
              >
                <!--二级菜单-->
                {{ subItem.title }}
              </el-menu-item>
            </template>
          </el-sub-menu>
        </template>
        <template v-else>
          <el-menu-item
            v-show="authorityNames.indexOf(item.authority) !== -1"
            :index="item.index"
            :key="item.index"
          >
            <!--一级菜单-->
            <i :class="item.icon"></i>
            <template #title>{{ item.title }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { getAuthority } from "@/api/user";

export default {
  data() {
    return {
      authorityNames: [],
      items: [
        {
          icon: "el-icon-lx-home",
          index: "dashboard",
          title: "系统首页",
          authority: "home",
        },
        {
          icon: "el-icon-coin",
          index: "data-manager",
          title: "数据管理",
          authority: "data",
          subs: [
            {
              index: "real-monitor",
              title: "实时监控",
              authority: "data:real",
            },
            // 地图
            {
              index: "monitory-point-map",
              title: "地图",
              authority: "data:real",
            },
            {
              index: "data-check",
              title: "数据查看",
              authority: "data:check",
            },
            {
              index: "data-alert",
              title: "报警数据",
              authority: "data:alert",
            },
          ],
        },
        {
          icon: "el-icon-s-data",
          index: "dataStatistic",
          title: "数据统计",
          authority: "statistic",
          subs: [
            {
              index: "dataIntegrityRate",
              title: "数据完整率",
              authority: "statistic:integrity",
            },
          ],
        },
        {
          icon: "el-icon-chat-dot-round",
          index: "info",
          title: "信息管理",
          authority: "info",
          subs: [
            {
              index: "area",
              title: "区域管理",
              authority: "info:area",
            },
            {
              index: "wechatBot",
              title: "群机器人管理",
              authority: "info:wechat-bot",
            },
            {
              index: "wechatBotQRCode",
              title: "微信登陆",
              authority: "info:wechat-bot-qr-code",
            },
            {
              index: "company",
              title: "企业管理",
              authority: "info:company",
              subs: [
                {
                  index: "companyInfo",
                  title: "企业信息",
                  authority: "info:company-info",
                },
                {
                  index: "companyType",
                  title: "企业类型",
                  authority: "info:company-type",
                },
                {
                  index: "companyLevel",
                  title: "企业级别",
                  authority: "info:company-level",
                },
              ],
            },
            {
              index: "point",
              title: "监控点管理",
              authority: "info:monitory-point",
              subs: [
                {
                  index: "pointInfo",
                  title: "监控点信息",
                  authority: "info:monitory-point-info",
                },
                {
                  index: "pointType",
                  title: "监控点类型",
                  authority: "info:monitory-point-type",
                },
              ],
            },
            {
              index: "pollution",
              title: "污染物管理",
              authority: "info:pollution",
              subs: [
                {
                  index: "pollutionInfo",
                  title: "污染物信息",
                  authority: "info:pollution-info",
                },
              ],
            },
            {
              index: "message",
              title: "短信配置",
              authority: "info:message",
            },
          ],
        },
        {
          icon: "el-icon-office-building",
          index: "room",
          title: "站房管理",
          authority: "room",
          subs: [
            {
              index: "equipment",
              title: "设备管理",
              authority: "room:equipment",
            },
          ],
        },
        {
          icon: "el-icon-notebook-1",
          index: "log",
          title: "日志管理",
          authority: "log",
          subs: [
            {
              index: "datagramLog",
              title: "报文日志",
              authority: "log:datagram",
            },
            {
              index: "counterchargeLog",
              title: "反控日志",
              authority: "log:countercharge",
            },
            {
              index: "operationLog",
              title: "操作日志",
              authority: "log:api",
            },
            {
              index: "offLineLog",
              title: "断线日志",
              authority: "log:break",
            },
            {
              index: "messageLog",
              title: "短信日志",
              authority: "log:message",
            },
            {
              index: "offLineStatisticLog",
              title: "断线统计",
              authority: "log:break-statistic",
            },
          ],
        },
        {
          icon: "el-icon-setting",
          index: "sys",
          title: "系统管理",
          authority: "sys",
          subs: [
            {
              index: "user",
              title: "用户管理",
              authority: "sys:user",
            },
            {
              index: "role",
              title: "角色管理",
              authority: "sys:role",
            },
          ],
        },
      ],
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
    collapse() {
      return this.$store.state.collapse;
    },
  },
  mounted() {
    const params = {
      username: "superAdmin",
    };
    getAuthority(params).then((result) => {
      this.authorityNames = result.data;
      console.log(JSON.stringify(this.authorityNames));
    });
  },
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}

.sidebar > ul {
  height: 100%;
}
</style>
